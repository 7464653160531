import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'

import * as Scroll from 'react-scroll'
let ScrollLink = Scroll.Link
let Element = Scroll.Element
let Events = Scroll.Events
let scroll = Scroll.animateScroll
let scrollSpy = Scroll.scrollSpy
let scroller = Scroll.scroller

import { Layout, Section, Block } from '../components'
import { media } from '../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../styles/global'
import { parseACF, getImageCrop, truncateString } from '../utils'
import { render } from 'react-dom'
import ProgressiveImage from 'react-progressive-image'
import { motion, transform } from 'framer-motion'

const WhatWeOffer = (props) => {
    const { x, y } = useWindowScroll()
    const { width, height } = useWindowSize()
    const [activeIndex, setActiveIndex] = useState(-1)
    const [truncate, setTruncate] = useState(true)

    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

    // Scroll-to Easing

    const handleClick = (e, to) => {
        e.preventDefault()
        e.stopPropagation()

        scroller.scrollTo(to, {
            duration: 1500,
            smooth: 'easeInOutCubic',
        })
    }

    // Intro

    const renderIntro = () => {
        return (
            <Intro>
                <Wrap>
                    <Title>What We Offer</Title>
                    <Text
                        dangerouslySetInnerHTML={{ __html: data.introduction }}
                    />
                </Wrap>
                <Wrap>
                    {data.hero_image && (
                        <Image
                            key={data.hero_image?.sizes?.medium2}
                            src={data.hero_image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                </Wrap>
            </Intro>
        )
    }

    // Jump Nav

    const renderJumpNav = () => {
        const items =
            data.classes &&
            data.classes.map((item, i) => {
                return (
                    <ScrollLink
                        key={i}
                        to={item.type.slug}
                        spy={true}
                        duration={500}
                        className="list-item"
                        activeClass="active"
                        onClick={(e) => handleClick(e, item.type.slug)}
                    >
                        {item.type.title}
                    </ScrollLink>
                )
            })

        return (
            <List>
                <Text>Jump to:</Text>
                {items}
            </List>
        )
    }

    // Classes

    const renderClasses = () => {
        return (
            data.classes &&
            data.classes.map((item, i) => {
                return (
                    <Classes
                        id={item.type.slug}
                        key={i}
                        active={activeIndex == i}
                    >
                        <Wrap>
                            <Heading>{item.type.title}</Heading>
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: item.type.description,
                                }}
                            />
                        </Wrap>
                        {renderClassItem(item.items, item.type.image)}
                    </Classes>
                )
            })
        )
    }

    const renderClassItem = (classes, image) => {
        if (!classes) return

        const items = classes.map((item, i) => {
            return (
                <ClassItem key={i} active={activeIndex == i}>
                    <Image
                        key={item.acf?.image?.sizes?.medium2}
                        src={item.acf?.image?.sizes?.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>

                    <Meta>
                        <Heading>{item.title}</Heading>
                        <SubHeading
                            dangerouslySetInnerHTML={{
                                __html: item.acf.sub_heading,
                            }}
                        />

                        {activeIndex != i && (
                            <>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: truncateString(
                                            item.acf.description.replace(
                                                /(<([^>]+)>)/gi,
                                                ''
                                            ),
                                            200
                                        ),
                                    }}
                                />

                                <Button
                                    onClick={() => {
                                        setTruncate(false)
                                        setActiveIndex(i)
                                    }}
                                >
                                    Read more
                                </Button>
                            </>
                        )}

                        {activeIndex == i && (
                            <>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: item.acf.description,
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        setTruncate(true)
                                        setActiveIndex(null)
                                    }}
                                >
                                    Read less
                                </Button>
                            </>
                        )}
                    </Meta>
                </ClassItem>
            )
        })

        return <ClassList>{items}</ClassList>
    }

    return (
        <Layout meta={data && data.seo}>
            <Container>
                {renderIntro()}
                {renderJumpNav()}
                {renderClasses()}

                <Block
                    layout={'big_button'}
                    display
                    text={data.bigbutton_text}
                    link={data.bigbutton_link}
                />
            </Container>
        </Layout>
    )
}

// Shared

const Title = styled.h1``
const Heading = styled.div``
const SubHeading = styled.div``
const Wrap = styled.div``
const ListItem = styled.div``
const Text = styled.div``
const Meta = styled.div``
const Button = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
    Margin-top: 200px;
`

// Intro

const Intro = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    ${media.tablet`
		flex-direction: column;	
		margin-bottom: 80px;
	`}

    ${Wrap} {
        flex-basis: calc(50% - 24px);

        ${Title} {
            ${type.heading1};
            padding-bottom: 32px;
        }
        ${Text} {
            ${type.body};
        }
        ${BGImage} {
            ${bgIcon};
            width: 100%;
            height: 100%;
            border-radius: 100%;
            min-height: 40vw;

            ${media.tablet`
				min-height: 382px;	
			`};
        }
    }
`

// Jump Nav

const List = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    ${media.tablet`
		margin-bottom: 80px;	
	`}

    ${Text} {
        font-size: 21px;
        padding-bottom: 20px;
    }

    .list-item {
        ${button};
        color: ${colours.black};
        margin-bottom: 12px;

        &:visited {
            color: inherit;
        }
    }
`

// Classes

const Classes = styled.div`
    margin-bottom: 90px;

    ${media.tablet`
		margin-bottom: 40px;	
	`}

    ${Wrap} {
        ${Heading} {
            ${type.heading1};
            padding-bottom: 24px;
        }
        ${Text} {
            ${type.bodySmall};
            max-width: 80%;

            ${media.tablet`
				max-width: 100%;
			`}
        }
    }
`

const ClassList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ClassItem = styled.div`
    width: 100%;
    max-width: 915px;

    display: flex;
    background: ${colours.lightBeige};
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-radius: 22px;

    padding: 38px;
    box-sizing: border-box;
    margin-bottom: 20px;

    ${media.tablet`
		flex-direction: column;	
		padding: 20px;
	`}

    ${(props) => {
        if (props.active == true)
            return css`
                background: ${colours.lightBlue};
            `
    }}

    &:hover {
        background: ${colours.lightBlue};
    }

    ${BGImage} {
        flex-basis: 50%;
        min-width: 280px;
        min-height: 238px;
        height: 100%;

        ${media.tablet`
			flex-basis: 100%;
			width: 172px;
			min-height: 142px;
			min-width: auto;
			margin-bottom: 20px;
		`}
    }

    ${Meta} {
        flex-basis: 100%;
        padding-left: 30px;

        ${media.tablet`
			width: 100%;
			padding-left: 0;
		`}

        ${Heading} {
            ${type.heading3};
            border-bottom: 3px solid ${colours.black};
            padding-bottom: 20px;
        }

        ${SubHeading} {
            font-size: 21px;
            padding: 20px 0;
        }

        ${Text} {
            ${type.bodySmall};

            p {
                ${type.bodySmall};
                margin: 0;
            }
        }

        ${Button} {
            ${type.bodySmall};
            text-decoration: underline;
            padding-top: 20px;
            cursor: pointer;
        }
    }
`

// Data

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "what-we-offer" } }) {
            nodes {
                acf_json
                title
            }
        }
    }
`

export default WhatWeOffer
